$(document).on('turbolinks:load', function() {

    $('.content-dropdown .collapse').on('show.bs.collapse', function() {
        $('.toggle-collapse').removeClass('collapsed');
    });

    $('.content-dropdown .collapse').on('hide.bs.collapse', function() {
        $('.toggle-collapse').addClass('collapsed');
    });

    $('.navbar').on('show.bs.collapse', function() {
        $('.navbar').addClass('toggled');
    });
    $('.navbar').on('hide.bs.collapse', function() {
        $('.navbar').removeClass('toggled');
    });
    
});

