// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

import Rails from "@rails/ujs"
import Turbolinks from "turbolinks"
import * as ActiveStorage from "@rails/activestorage"
import "channels"


require("jquery")
require("flatpickr")
require("bootstrap/dist/js/bootstrap")
require("slick-carousel")
require("./collapse.js")
require("./map")
require("./contact")
require('./table')
require("./datepicker")
require("packs/nested-forms/addFields")
require("packs/nested-forms/removeFields")
require("./announcement");
require ("./sign_up");
require ("./credit_card_form.js.erb");

// flatpickr
require("flatpickr/dist/flatpickr.css");

Rails.start()
Turbolinks.start()
ActiveStorage.start()

import "@fortawesome/fontawesome-pro/js/all.js"
import "@fortawesome/fontawesome-pro/css/all.css"
import 'slick-carousel';
import "slick-carousel/slick/slick.scss"; 
import "slick-carousel/slick/slick-theme.scss";
import '../../stylesheets/application.scss';

// Uncomment to copy all static images under ../images to the output folder and reference
// them with the image_pack_tag helper in views (e.g <%= image_pack_tag 'rails.png' %>)
// or the `imagePath` JavaScript helper below.
const images = require.context('../../images', true)
const imagePath = (name) => images(name, true)

window.bootstrap = require('bootstrap/dist/js/bootstrap.bundle.js');

//TEST CHANGE
