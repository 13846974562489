$(document).on('turbolinks:load', function() {
  $('.btnNext').click(function(e) {
      e.preventDefault();
      const nextTabLinkEl = $('.nav-tabs .active').closest('li').next('li').find('a')[0];
      const nextTab = new bootstrap.Tab(nextTabLinkEl);
      nextTab.show();
      $('html,body').animate({scrollTop: $("#registration-page-top").offset().top},'slow');
  });

  $('.btnPrevious').click(function(e) {
      e.preventDefault();
      const prevTabLinkEl = $('.nav-tabs .active').closest('li').prev('li').find('a')[0];
      const prevTab = new bootstrap.Tab(prevTabLinkEl);
      prevTab.show();
      $('html,body').animate({scrollTop: $("#registration-page-top").offset().top},'slow');
  });

  $('input[type=checkbox].company-name-input').change(function(e) {
    if($(this).is(':checked')){
      $("#company-name").removeClass('d-none');
    } else {
      $("#company-name").addClass('d-none');
    }
  });
});
