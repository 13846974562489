$(document).on('turbolinks:load', function() {
    
    if ($('.contact_request').length) {
        handler = Gmaps.build('Google');
        handler.buildMap({
          provider: {
            backgroundColor: 'white',
            disableDefaultUI: true,
            zoomControl: true,
          },
          internal: {
            id: 'map'
          }
        },
        function() {
          markers = handler.addMarkers([
              {
                  "lat": 41.161377,
                  "lng": -111.968646,
              }
          ]);
          handler.bounds.extendWith(markers);
          handler.fitMapToBounds();
          handler.getMap().setZoom(14);

          // Potentially append info card when map renders
          google.maps.event.addListenerOnce(handler.getMap(), 'idle', function(e) {
              $(".gm-style").append('');
          });
        }
        );  
    }
});