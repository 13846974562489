class initializeAnnouncement {
    constructor() { 
        this.announcements = document.querySelectorAll('#fixedAnnouncement');
        this.init();
    }
 
    init() {
        this.wrapAnnouncements();
        this.addAnnouncement();
    }

    wrapAnnouncements() {
        this.announcements.forEach(function(announcement) {
            $(announcement).wrap("<div class='fixedAnnouncement-trigger'></div>"); 
        })
    }

    addAnnouncement() {
        if (this.announcements.length == 0) return;

        var wrapper = document.querySelectorAll('.fixedAnnouncement-trigger');

        wrapper.forEach(function(announcement) { 
            $(announcement).addClass('slide-announcement-out');

            announcement.addEventListener('mouseenter', function() {
                $(this).addClass('slide-announcement-out');
                $(this).removeClass('wasHovered');
            });

            // announcement.addEventListener('mouseleave', function() {
            //     var that = this;
            //     setTimeout(function() {
            //         $(that).removeClass('slide-announcement-out')
            //         $(that).addClass('wasHovered')  
            //     }, 1000);
            // });

            // announcement.addEventListener('click', function(e) {
            //     if ($(e.target).prop('id') == 'announcement-close' || $('#announcement-close').has(e.target).length) {
            //         $(this).children('div').first().addClass('dismissed-announcement');
            //     }
            // });

        });

    }

}

window.addEventListener('turbolinks:load', () => new initializeAnnouncement());
