$(document).ready(function() {

if ($('.clover-form').length) {
      const clover = new Clover('7387a382fec3c7beb71b0db3ad79e67f');
      const elements = clover.elements();
      const form = document.getElementById('paymentForm');
      const styles = {
      'input': {
        'width': 'calc(100% - 5px)',
        'font-size': '20px',
        'padding': '8px',
        'min-height': 'calc(1.5em + 1rem + 2px)',
        'font-size': '1.25rem',
        'border-radius': '0.3rem',
        'box-shadow': '1px 1px 1px rgba(0, 0, 0, 0.1)',
        'margin': '3px',
        'font-weight': '400',
        'font-family': 'Open Sans, sans-serif',
        'background-color': 'white',
        'transition': 'border-color 0.25s ease-in-out, box-shadow 0.25s ease-in-out',
        'border': '2px solid white',
        'max-width': 'calc(100% - 6px) !important',
        'max-height': '54px',
        'overflow': 'hidden',
      },
      'input::placeholder': {
        'font-weight': '200',
      },
      'input:focus, input:active': {
        'box-shadow': '0 0 0 0.25rem rgba(13, 110, 253, .25)',
        'outline': 'none !important',
      },
      'card-number': {
        'position': 'relative',
      },
      '.brand': {
        'display': 'flex',
        'flex-direction': 'column',
        'justify-content': 'center',
        'align-items': 'center',
        'top': '25%',
        'bottom': '50%',
        'position': 'absolute',
        'transform': 'translateY(-50%)',
        'right': '25px',
      }
    }; 
  
    const cardNumber = elements.create('CARD_NUMBER', styles);
    const cardDate = elements.create('CARD_DATE', styles);
    const cardCvv = elements.create('CARD_CVV', styles);
    const cardPostalCode = elements.create('CARD_POSTAL_CODE', styles);
  
    cardNumber.mount('#card-number');
    cardDate.mount('#card-date');
    cardCvv.mount('#card-cvv');
    cardPostalCode.mount('#card-postal-code');
  
    const cardResponse = document.getElementById('card-response');
  
    // Listen for form submission
    form.addEventListener('submit', function(event) {
      event.preventDefault();
      // Use the iframe's tokenization method with the user-entered card details
      clover.createToken()
        .then(function(result) {
        if (result.errors) {
          for (var key in result.errors) {
            $(`div[data-error='${key}']`).html(result.errors[key])
            $(`div[data-error='${key}'].inline-form-errors`).css('display', 'block');
          }
        } else {
          cloverTokenHandler(result.token);
        }
      });
    });
  
    function cloverTokenHandler(token) {
      // Insert the token ID into the form so it gets submitted to the server
      var form = document.getElementById('paymentForm');
      var hiddenInput = document.createElement('input');
      hiddenInput.setAttribute('type', 'hidden');
      hiddenInput.setAttribute('name', 'cloverToken');
      hiddenInput.setAttribute('value', token);
      form.appendChild(hiddenInput);
      form.submit();
    }
  }
})
