$(document).on('turbolinks:load', function() {

    const fp =  flatpickr("[data-behavior='flatpickr']", {});

    if (fp.length < 1) {
        flatpickr("[data-behavior='flatpickr']", {
            altInput: true,
            altFormat: "m-d-Y",
            dateFormat: "Y-m-d",
            allowInput: true,
        });
    }
    
});